<template>
  <el-dialog
    :title="$t('Forwarder.Add Forwarder')"
    :visible.sync="show"
    width="358px"
    append-to-body
    :before-close="() => $emit('update:show', false)"
    custom-class="forwarderDialog"
    @open="open"
    @close="close"
    v-move-outside
  >
    <div class="subject">
      <el-select
        v-model="companyId"
        size="small"
        :placeholder="$t('order.Select Forwarder')"
        clearable
      >
        <el-option
          v-for="(item, index) in companyList"
          :key="index"
          :label="item.aliasName || item.name"
          :value="item.companyId"
        />
      </el-select>
    </div>
    <div class="subject_table">
      <template v-if="companyId">
        <div class="item" v-for="(item, index) in forwarderUserList" :key="index">
          <member
            :info="item"
            :size="48"
            :placement="'left'"
          />
          <div class="content" @click.prevent="goChecked(item)">
            <div class="nameBox">
              <span class="name">{{ item.nickName }}</span>
              <span class="manageTag" v-if="item.type === 0">{{ $t('memberList.manage') }}</span>
            </div>
            <div class="duties">
              {{ item.position }}
            </div>
          </div>
          <el-checkbox v-model="item.check"></el-checkbox>
        </div>
      </template>
      <el-empty 
        v-else 
        :description="$t('overallSituation.noData')" 
        :image="$emptyImage"
        :image-size="90"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button 
        class="cancel_button" 
        size="small" 
        @click="$emit('update:show', false)">
        {{ $t('home.Cancel') }}
      </el-button>
      <el-button type="primary" size="small" @click="confirm">
        {{ $t('home.Confirm') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getForwarderMemberList, getUserCustomList } from '@/api/customer';
import { updateForWordCompany } from "@/api/order";

export default {
  name: 'addForwarder',
  props: {
    type: {
      type: String,
      default: "add"
    },
    show: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: Number | String,
      default: ""
    }
  },
  data() {
    return {
      companyId: "",
      companyList: [],
      forwarderUserList: [],
      forwarderLoading: false
    }
  },
  watch: {
    companyId(val) {
      if(val) {
        this.getList(val);
      }
    }
  },
  methods: {
    getList(val) {
      this.forwarderLoading = true;
      if (val) {
        getForwarderMemberList(val)
          .then((data) => {
            this.forwarderUserList = data.memberList.map((v) => ({
              ...v,
              check: false
            }));
          })
          .finally(() => (this.forwarderLoading = false));
      } else {
        this.forwarderLoading = false;
      }
    },
    goChecked(row) {
      row.check = !row.check;
    },
    getForwarderData() {
      getUserCustomList({
        existsOrderId: this.orderId,
        companyType: '2',
        currentPage: 1,
        pageSize: 100000
      }).then((data) => {
        this.companyList = data.list;
      })
    },
    confirm() {
      let arr = this.forwarderUserList.filter(item => item.check);
      if(this.type == 'add') {
        this.$emit("updateConfirm", {
          arr,
          companyId: this.companyId
        });
        this.$emit("update:show", false);
        return;
      }
      if (
        !this.companyId ||
        arr.length == 0
      ) {
        this.$message.warning(this.$t("todo.Please select the freight"));
      } else {
        this.$confirm(this.$t("order.Add the forwarder"), "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.forwarderLoading = true;
          updateForWordCompany({
            forwarderCompanys: this.companyId,
            groupUsers: arr.map(item => item.openId).join(","),
            orderId: this.orderId,
          }).then(() => {
            this.forwarderUserList = [];
            this.companyId = "";
            this.$emit("update:show", false);
            this.$emit("updateConfirm");
          }).finally(() => {
            this.forwarderLoading = false;
          });
        }).catch(() => {});
      }
    },
    open() {
      this.getForwarderData();
    },
    close() {
      this.companyId = "";
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 8px 0;
  .subject {
    padding: 0 16px;
    margin-bottom: 10px;
  }
  .subject_table {
    height: 230px;
    overflow: overlay;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      gap: 10px;
      cursor: pointer;
      &:hover {
        background: #F7F9FC;
      }
      .content {
        flex: 1;
        .nameBox {
          font-size: 14px;
          line-height: 20px;
          color: #122545;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          .name {
            display: inline-block;
            max-width: 140px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .manageTag {
            font-size: 12px;
            line-height: 17px;
            padding: 1px 5px;
            background: #F7F9FC;
            color: #076F49;
            border-radius: 4px;
            margin-left: 6px;
          }
        }
        .duties {
          font-size: 12px;
          line-height: 17px;
          color: #757D8A;
        }
      }
      // .el
    }
  }
}
</style>