<template>
  <el-drawer
    :title="$tc('home.Order import', $isOrderTitle())"
    :visible.sync="isOrderImport"
    :modal="false"
    size="364px"
    :before-close="() => CloseDrawer()"
    v-move-outside
  >
    <el-form
      class="drawerForm"
      size="small"
      ref="orderImportform"
      :rules="companyDetailRules"
      :validate-on-rule-change="false"
      :model="form"
    >
      <el-form-item prop="customerId">
        <div class="title">{{ $t('home.Customer') }}</div>
        <div class="avatarForm">
          <el-select 
            v-model="form.customerId" 
            clearable
            filterable
            :placeholder="`${$t('home.Pleaseselect')} ${$t(
              'xiaoxie.customer'
            )}`"
            style="flex: 1;"
            @change="handleSelect">
            <el-option 
              v-for="(item, index) in customerList" 
              :key="index" 
              :label="item.aliasName" 
              :value="item.companyId">
              <div class="customer_name">
                <el-image :src="item.companyLogoUrl" fit="cover" />
                <div class="companyName">
                  <div class="name">{{ item.aliasName || item.name }}</div>
                  <div class="type">{{ $t(`home.${$companyTypes[item.companyType]}`) }}</div>
                </div>
              </div>
            </el-option>
          </el-select>
          <el-avatar :src="companyImage" fit="cover" />
        </div>
      </el-form-item>
      <el-form-item prop="importCountry">
        <div class="title">{{ $t('home.country') }}</div>
        <el-select
          v-model="form.importCountry"
          clearable
          filterable
          :placeholder="`${$t('home.Pleaseselect')} ${$t('xiaoxie.country')}`"
        >
          <el-option
            v-for="(item, index) in minuteCountry"
            :key="index"
            :label="item.countryName"
            :value="item.iso3"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="groupUsers">
        <div class="title">
          {{ $t('home.Groups') }}
        </div>
        <el-button 
          style="width: 100%;" 
          icon="el-icon-plus" 
          :disabled="!form.customerId"
          v-if="!form.groupUsers.length"
          @click="createOrderGroupShow = true"
        >{{ $t('Files.Select people') }}</el-button>
        <div class="selectUserBox" v-else>
          <div class="avatarBox">
            <el-image 
              v-for="(row, idx) in form.groupUsers" 
              :key="idx" 
              :src="row.avatarFileUrl"
              fit="cover">
            </el-image>
          </div>
          <div class="groupName">
            <div class="name">
              {{ form.groupUsers.map(item => item.nickName).join(',') }}
            </div>
          </div>
          <div class="edit" @click="createOrderGroupShow = true">{{ $t('home.edit') }}</div>
        </div>
      </el-form-item>
      <el-form-item prop="file">
        <div class="title">{{ $tc('order.Historical orders', $isOrderTitle()) }}</div>
        <el-upload
          action="#"
          drag
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="confirmUpload"
          :on-remove="fileRemove"
          class="ec-upload"
          :headers="{ 'Content-Type': 'multipart/form-data' }"
          :class="!fileData ? 'ec-upload' : 'ec-uploader'"
        >
          <template v-if="!fileData">
            <i class="iconfont icon-shangchuan"></i>
            <div class="el-upload__text">
              {{ $t('event.Drag & Drop files here OR Browse Files') }}
            </div>
          </template>
          <template v-else>
            <div class="ec-upload__left">
              <i class="iconfont_file" :class="isFileTypeIcon(form.file.name.split('.')[1])"></i>
              <div class="name">
                <p>{{ form.file.name }}</p>
              </div>
            </div>
            <i class="iconfont icon-shanchu" @click.stop="deleteFile"></i>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <div class="title">{{ $t('home.AddForwarder') }}</div>
        <el-input
          clearable
          :placeholder="forwarder_group || $t('home.AddForwarder')"
          @click.native="forwarderDialogShow = true"
        />
      </el-form-item>
    </el-form>
    <div class="drawerFooter">
      <el-button
        size="small"
        :disabled="buttonLoading"
        :loading="disabledDownload"
        @click="download"
      >
        {{ $t('home.Download template') }}
      </el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="disabledDownload"
        :loading="buttonLoading"
        @click="createOrder()"
      >
        {{ $tc('home.createOrder', $isOrderTitle()) }}
      </el-button>
    </div>
    <!-- 卖家买家人员选择 -->
    <createOrderGroup
      :createOrderGroupShow.sync="createOrderGroupShow"
      :customerCompanyId="companyCustomerId+'|'+form.customerId"
      :groupUserList.sync="form.groupUsers"
      @handleClose="createOrderGroupShow = false"
    />
    <!--添加货代-->
    <addForwarder 
      type="add"
      :show.sync="forwarderDialogShow" 
      @updateConfirm="updateForwarderConfirm"
    />
  </el-drawer>
</template>

<script>
import createOrderGroup from '@/components/createOrderGroup/createOrderGroup';
import addForwarder from "@/components/addForwarder/addForwarder.vue";
import { mapGetters } from 'vuex';
import {  importHistoryOrder, getImportHistoryProgress } from '@/api/order';
import { isFileTypeIcon } from "@/utils/utils.js";
export default {
  name: 'orderImport',
  components: {
    createOrderGroup,
    addForwarder
  },
  inject: ['reload'],
  data() {
    return {
      isFileTypeIcon: isFileTypeIcon,
      disabledDownload: false,
      fileData: '',
      companyImage: "",
      companyCustomerId: "",
      form: {
        exportCountry: '',
        importCountry: "",
        customerId: '',
        file: '',
        groupUsers: [],
        forwarderCompanys: '',
      },
      createOrderGroupShow: false,
      buttonLoading: false,
      companyDetailRules: {
        file: [
          {
            required: true,
            message: this.$t('home.Please upload the history file'),
          },
        ],
        importCountry: [
          {
            required: true,
            message: this.$t('home.Pleaseselectcountry'),
          },
        ],
        customerId: [
          {
            required: true,
            message: this.$t('home.PleaseselectCustomer'),
          },
        ],
        groupUsers: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error(this.$t('home.Pleaseselectmember')));
              } else {
                callback();
              }
            },
          },
        ],
      },
      // 货代公司
      forwarder_group: "",
      forwarderDialogShow: false,
      forforwarderUserList: [],
      importInterTime: null
    };
  },
  props: ['isOrderImport', 'customerList', 'companyList'],
  computed: {
    ...mapGetters(['companyId', 'minuteCountry']),
    documentPlaceholder() {
      return this.fileData
        ? this.fileData.name
        : this.$t('home.SelectDocument');
    }
  },
  watch: {
    'form.company'(val) {
      this.form.groupUserList = [];
    },
    forforwarderUserList(val) {
      if (val.length == 0) {
        return (this.forwarder_group = this.$t('home.Clicktoselectforwarder'));
      } else {
        return (this.forwarder_group = val.length + this.$t('home.eopleSelected'));
      }
    },
  },
  methods: {
    updateForwarderConfirm(data) {
      this.form.forwarderCompanys = data.companyId,
      this.forforwarderUserList = data.arr;
    },
    fileRemove() {
      this.fileData = "";
      this.form.file = "";
    },
    deleteFile() {
      this.fileData = "";
      this.form.file = "";
    },
    beforeAvatarUpload(file) {
      const isLt30M = file.size / 1024 / 1024 < 300;
      const type = file.name.indexOf('.csv') != -1;
      if (!isLt30M) {
        this.$message.error(this.$tc('order.File is too large to upload', 300));
      } else if (!type) {
        this.$message.error('上传文件格式不正确!');
      }
      return isLt30M && type;
    },
    confirmUpload({file}, fileList) {
      if (!this.beforeAvatarUpload(file)) return;
      this.fileData = file;
      this.form.file = file;
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          (restaurant.name += '')
            .toLowerCase()
            .indexOf((queryString += '').toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.form.exportCountry = this.$isFindData(this.customerList, item, 'companyId', 'country');
      this.companyImage = this.$isFindData(this.customerList, item, 'companyId', 'companyLogoUrl');
      this.companyCustomerId = this.$isFindData(this.customerList, item, 'companyId', 'companyCustomerId');
    },
    createOrder() {
      this.buttonLoading = true;
      const { forwarderCompanys, file } = this.form;
      let formData = new FormData();
      formData.append('file', file);
      formData.append(
        'forwarderCompanys',
        forwarderCompanys.length ? [forwarderCompanys].join(',') : []
      );
      formData.append(
        'groupUsers',
        this.form.groupUsers.concat(this.forforwarderUserList).map(item => item.openId).join(',')
      );
      console.log(this.forforwarderUserList)
      formData.append('importCountry', this.form.importCountry);
      formData.append('exportCountry', this.form.exportCountry);
      formData.append('customerId', this.form.customerId);
      this.$refs['orderImportform'].validate((valid) => {
        if (valid) {
          importHistoryOrder(formData)
            .then((data) => {
              this.$message.success(this.$t('home.Createdsuccessfully'));
              this.$emit('successConfirm');
              this.CloseDrawer(true);
              this.importInterTime = setInterval(() => {
                console.log(data)
                getImportHistoryProgress(data).then((num) => {
                  console.log(num)
                  this.$store.dispatch("user/setImportProgress", {
                    show: true,
                    value: Number(num)
                  })
                  if(num == 100) {
                    clearInterval(this.importInterTime);
                    this.$store.dispatch("user/setImportProgress", {
                      show: false,
                      value: 0
                    })
                  }
                })
              }, 1000)
            })
            .finally(() => (this.buttonLoading = false));
        } else {
          this.buttonLoading = false;
        }
      });
    },
    download() {
      const link = document.createElement('a');
      link.href = this.$store.state.user.orderAlias == 0 ? "https://echola-business.oss-cn-hangzhou.aliyuncs.com/file/template/echola%E5%8E%86%E5%8F%B2%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.csv" : "";
      link.setAttribute('download', '历史订单导入模板.xls');
      document.body.appendChild(link);
      link.click();
    },
    CloseDrawer(is = false) {
      this.form = {
        exportCountry: '',
        importCountry: "",
        customerId: '',
        file: '',
        groupUsers: [],
        forwarderCompanys: '',
      }
      this.forforwarderUserList = [];
      this.fileData = '';
      this.$emit('onClose', is);
    },
  },
};
</script>
<style lang="less" scoped>
.customer_name {
  display: flex;
  align-items: center;
  .el-image {
    width: 44px;
    height: 33px;
    border-radius: 4px;
    margin-right: 8px;
  }
  .companyName {
    flex: 1;
    .name {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #122545;
    }
    .type {
      font-size: 12px;
      line-height: 17px;
      color: #757D8A;
    }
  }
}
.selectUserBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  .avatarBox {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    background: #E0E4EA;
    .el-image {
      width: 16px;
      height: 16px;
    }
  }
  .groupName {
    margin-left: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #122545;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .edit {
    color: #076F49;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
  }
}
</style>
