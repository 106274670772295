<template>
  <el-drawer
    :title="
      action == 'create'
        ? `${$tc('home.createOrder', $isOrderTitle())}`
        : action == 'purchase' ? `${$tc('home.generateOrder', $isOrderTitle())}` 
        : `${$tc('home.updateOrder', $isOrderTitle())}`
    "
    :visible.sync="addShipmentShow"
    :before-close="() => CloseDrawer(true)"
    size="400px"
    v-move-outside
  >
    <el-form 
      :model="form" 
      :rules="rules" 
      ref="createForm" 
      v-loading="loadingBox"
      label-width="0"
      size="small"
      class="drawerForm">
      <el-form-item prop="customerId">
        <div class="title">{{ $t('home.Customer') }}</div>
        <div class="avatarForm">
          <el-select 
            v-model="form.customerId" 
            clearable
            filterable
            appendToBody
            :disabled="action == 'edit' || action == 'purchase' || Boolean(this.customerInfo)"
            :placeholder="`${$t('home.Pleaseselect')} ${$t(
              'xiaoxie.customer'
            )}`"
            style="flex: 1;"
            @change="handleSelect">
            <el-option 
              v-for="(item, index) in customerList" 
              :key="index" 
              :label="item.aliasName" 
              :value="item.companyId">
              <div class="customer_name">
                <el-image :src="item.companyLogoUrl" fit="cover" />
                <div class="companyName">
                  <div class="name">{{ item.aliasName || item.name }}</div>
                  <div class="type">{{ $t(`home.${$companyTypes[item.companyType]}`) }}</div>
                </div>
              </div>
            </el-option>
          </el-select>
          <el-avatar :src="companyImage" fit="cover" />
        </div>
      </el-form-item>
      <el-form-item
        prop="ci"
        v-if="action == 'edit' && editContent.ci && $store.state.user.orderAlias == 0"
      >
        <div class="title">{{ $t('home.CInumber') }}</div>
        <el-input
          v-model="form.ci"
          clearable
          :placeholder="$t('home.CInumber')"
        />
      </el-form-item>
      <el-form-item prop="pi">
        <div class="title">{{ $isOrderPITitle() }}</div>
        <el-input
          v-model="form.pi"
          clearable
          :disabled="Boolean(action == 'edit' && editContent.ci)"
          :placeholder="$isOrderPITitle()"
        />
      </el-form-item>
      <el-form-item prop="importCountry">
        <div class="title">{{ $t('home.country') }}</div>
        <el-select
          v-model="form.importCountry"
          clearable
          filterable
          appendToBody
          :placeholder="`${$t('home.Pleaseselect')} ${$t('xiaoxie.country')}`"
        >
          <el-option
            v-for="(item, index) in minuteCountry"
            :key="index"
            :label="item.countryName"
            :value="item.iso3"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="currency">
        <div class="title">{{ $t('home.Currency') }}</div>
        <el-select
          v-model="form.currency"
          clearable
          filterable
          appendToBody
          :placeholder="`${$t('home.Pleaseselect')} ${$t(
            'xiaoxie.currency'
          )}`"
        >
          <el-option
            v-for="(item, index) in currencys"
            :key="index"
            :label="item.key"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="amount">
        <div class="title">{{ $tc('home.OrderAmount', $isOrderTitle()) }}</div>
        <el-input-number
          style="width: 100%"
          controls-position="right"
          :min="0"
          v-model="form.amount"
          clearable
          :placeholder="`${$tc('home.OrderAmount', $isOrderTitle())}`"
        />
      </el-form-item>
      <el-form-item v-if="action == 'create' || action == 'purchase'">
        <div class="title">{{ $t('Purchase.Delivery terms') }}</div>
        <el-select
          v-model="form.transaction_terms"
          clearable
          filterable
          appendToBody
          :placeholder="$t('Purchase.Delivery terms')"
        >
          <el-option
            v-for="(item, index) in payMentData"
            :key="index"
            :label="item.key"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="action == 'create' || action == 'purchase'"
        prop="groupUsers"
      >
        <div class="title">
          {{ $t('home.Groups') }}
        </div>
        <el-button 
          style="width: 100%;" 
          icon="el-icon-plus" 
          :disabled="!form.customerId"
          v-if="!form.groupUsers.length"
          @click="createOrderGroupShow = true"
        >{{ $t('Files.Select people') }}</el-button>
        <div class="selectUserBox" v-else>
          <div class="avatarBox">
            <el-image 
              v-for="(row, idx) in form.groupUsers" 
              :key="idx" 
              :src="row.avatarFileUrl"
              fit="cover">
            </el-image>
          </div>
          <div class="groupName">
            <div class="name">
              {{ form.groupUsers.map(item => item.nickName).join(',') }}
            </div>
          </div>
          <div class="edit" @click="createOrderGroupShow = true">{{ $t('home.edit') }}</div>
        </div>
      </el-form-item>
      <el-form-item v-if="action == 'create' || action == 'purchase'">
        <div class="title">{{ $t('home.AddForwarder') }}</div>
        <el-input
          clearable
          :placeholder="forwarder_group || $t('home.AddForwarder')"
          @click.native="forwarderDialogShow = true"
        />
      </el-form-item>
      <el-form-item v-if="action == 'purchase' && purchase">
        <div class="title">{{ $t('Purchase.title') }}</div>
        <div class="purchaseBox">
          <div class="left">
            <div class="iconBox">
              <i class="iconfont icon-caigoudanguanli"></i>
            </div>
            {{ purchase.purchaseNo }}
          </div>
          <!-- <div class="seePurchase">{{ $t('see') }}</div> -->
        </div>
      </el-form-item>
    </el-form>
    <div class="drawerFooter">
      <el-button class="cancel_button" size="small" @click="CloseDrawer(true)">{{ $t('home.Cancel') }}</el-button>
      <el-button 
        size="small"
        v-permission="'import:order:btn'"
        @click="isOrderImport = true"
        v-if="action === 'create' && $store.state.user.orderAlias == 0"
      >{{ $tc('home.Order import', $isOrderTitle()) }}</el-button>
      <el-button 
        type="primary" 
        size="small"
        @click="createOrder"
        :loading="buttonLoading"
        v-if="action === 'create' || action == 'purchase'"
      >{{ $tc('home.createOrder', $isOrderTitle()) }}</el-button>
      <el-button 
        type="primary" 
        size="small"
        @click="updateOrder"
        :loading="buttonLoading"
        v-else
      >{{ $tc('home.updateOrder', $isOrderTitle()) }}</el-button>
    </div>
    <!-- 卖家买家人员选择 -->
    <createOrderGroup
      :createOrderGroupShow.sync="createOrderGroupShow"
      :customerCompanyId="companyCustomerId+'|'+form.customerId"
      :groupUserList.sync="form.groupUsers"
      :purchase="purchase"
      @handleClose="createOrderGroupShow = false"
    />
    <!-- 订单导入 -->
    <orderImport
      :isOrderImport="isOrderImport"
      :customerList="customerList"
      :editContent="editContent"
      :customerInfo="customerInfo"
      @successConfirm="CloseDrawer"
      @onClose="CloseDrawer"
    />
    <!--添加货代-->
    <addForwarder 
      type="add"
      :show.sync="forwarderDialogShow" 
      @updateConfirm="updateForwarderConfirm"
    />
  </el-drawer>
</template>

<script>
import {
  createCreatePIOrder,
  updateBusinessOrder,
} from '@/api/order';
import { mapGetters } from 'vuex';
import { uploadFile } from '@/api/files';
import { getUserCustomList } from "@/api/customer";
import { isFileTypeIcon } from "@/utils/utils.js";

import createOrderGroup from '@/components/createOrderGroup/createOrderGroup';
import addForwarder from "@/components/addForwarder/addForwarder.vue";
import orderImport from './orderImport';
import { Console } from 'console';

export default {
  name: 'createOrder',
  components: {
    createOrderGroup,
    orderImport,
    addForwarder
  },
  inject: ['reload'],
  data() {
    return {
      addShipmentShow: false,
      isFileTypeIcon: isFileTypeIcon,
      companyImage: "",
      isOrderImport: false,
      loadingBox: false,
      customerList: [],
      companyCustomerId: "",
      form: {
        pi: '',
        ci: '',
        importCountry: '',
        exportCountry: '',
        amount: undefined,
        currency: '',
        customerId: '',
        file: {
          file: null,
          fileId: ""
        },
        transaction_terms: "",
        groupUsers: [],
        forwarderCompanys: "",
        orderId: '',
      },
      rules: {
        pi: [
          {
            required: true,
            message: this.$tc('home.PleaseinputPInumber', this.$isOrderTitle()),
          },
        ],
        ci: [
          {
            required: true,
            message: this.$t('home.PleaseinputCInumber'),
          },
        ],
        importCountry: [
          {
            required: true,
            message: this.$t('home.Pleaseselectcountry'),
          },
        ],
        customerId: [
          {
            required: true,
            message: this.$t('home.PleaseselectCustomer'),
          },
        ],
        currency: [
          {
            required: true,
            message: this.$t('home.Pleaseselectcurrency'),
          },
        ],
        amount: [
          {
            required: true,
            message: this.$t('home.Pleaseinputamount'),
          },
        ],
        groupUsers: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error(this.$t('home.Pleaseselectmember')));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },
        ],
      },
      forwarder_group: "",
      forwarderDialogShow: false,
      forforwarderUserList: [],
      createOrderGroupShow: false,
      buttonLoading: false,
      uploadLoading: false,
      purchase: null
    };
  },
  watch: {
    'form.customerId'(val) {
      this.form.groupUserList = [];
      if(!val) {
        this.companyImage = "";
      }
    },
    forforwarderUserList(val) {
      if (val.length == 0) {
        return (this.forwarder_group = this.$t('home.Clicktoselectforwarder'));
      } else {
        return (this.forwarder_group =
          val.length + this.$t('home.eopleSelected'));
      }
    },
  },
  computed: {
    ...mapGetters([
      'currencyType',
      'orderProgress',
      'companyId',
      'minuteCountry',
      'currencys',
      'payMentData'
    ]),
  },
  props: [
    'editContent',
    'action',
    'customerInfo'
  ],
  methods: {
    open(form) {
      this.addShipmentShow = true;
      this.$nextTick(() => {
        this.$refs.createForm.clearValidate();
        this.loadingBox = true;
        getUserCustomList({
          currentPage: 1,
          pageSize: 100,
          companyType: "0",
        }).then((response) => {
          if (!response) return;
          this.customerList = response.list;
          if(this.customerInfo) {
            this.handleSelect(this.customerInfo.companyId);
            this.form.customerId = this.customerInfo.companyId;
          }
          if (this.action == 'edit') {
            this.form = {
              pi: '',
              ci: '',
              importCountry: '',
              exportCountry: '',
              amount: undefined,
              currency: '',
              customerId: '',
              file: {
                file: null,
                fileId: ""
              },
              transaction_terms: "",
              groupUsers: [],
              forwarderCompanys: "",
              orderId: '',
            };
            this.companyCustomerId = '';
            this.form.pi = this.editContent.pi;
            this.form.ci = this.editContent.ci;
            this.form.importCountry = this.editContent.country;
            this.form.currency = this.editContent.currency;
            this.form.amount = this.editContent.amount;
            this.form.orderId = this.editContent.orderId;
            this.form.transaction_terms = this.editContent.transaction_terms;
            this.form.customerId = this.editContent.customerId;
            this.companyImage = this.$isFindData(this.customerList, this.form.customerId, 'companyId', 'companyLogoUrl');
          } else if(this.action == 'purchase') {
            this.form = {
              pi: '',
              ci: '',
              importCountry: '',
              exportCountry: '',
              amount: undefined,
              currency: '',
              customerId: '',
              file: {
                file: null,
                fileId: ""
              },
              transaction_terms: "",
              groupUsers: [],
              forwarderCompanys: "",
              orderId: '',
            };
            this.companyCustomerId = '';
            this.purchase = form;
            this.form.customerId = form?.buyerCompanyId;
            this.form.pi = form.purchaseNo;
            this.form.importCountry = form.openUserDto?.company.country;
            this.form.currency = form.totalPriceUnit;
            this.form.amount = form.totalPrice;
            this.form.transaction_terms = form.transactionTerms;
            this.handleSelect(form?.buyerCompanyId);
          }
        }).finally(() => {
          this.loadingBox = false;
        });
      })
    },
    updateForwarderConfirm(data) {
      this.form.forwarderCompanys = data.companyId,
      this.forforwarderUserList = data.arr;
    },
    handleSelect(item) {
      this.form.exportCountry = this.$isFindData(this.customerList, item, 'companyId', 'country');
      this.companyImage = this.$isFindData(this.customerList, item, 'companyId', 'companyLogoUrl');
      this.companyCustomerId = this.$isFindData(this.customerList, item, 'companyId', 'companyCustomerId');
    },
    deleteFile() {
      this.form.file = {
        file: null,
        fileId: ""
      };
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error(this.$tc('order.File is too large to upload', 100));
      }
      return isLt2M;
    },
    confirmUpload(file) {
      if (!this.beforeAvatarUpload(file)) return;
      this.form.file.file = file.raw;
    },
    createOrder() {
      var data = {
        pi: this.form.pi, //pi号
        importCountry: this.form.importCountry, //国家
        exportCountry: this.form.exportCountry,
        amount: this.form.amount, //金额
        currency: this.form.currency, //货币类型
        groupUsers: this.form.groupUsers.concat(this.forforwarderUserList).map(item => item.openId).join(","), //订单成员
        fileId: this.form.file.fileId, //文件id列表
        forwarderCompanys: this.form.forwarderCompanys.length ? [this.form.forwarderCompanys] : [], //货代id
        customerId: this.form.customerId, //买家公司id
        transactionTerms: this.form.transaction_terms,
        purchaseId: this.purchase?.purchaseId
      };
      this.$refs.createForm.validate((valid) => {
        if (!valid) return;
        if(this.form.file.file) {
          this.$myEvent.emit("uploadFile", {
            apiFn: createCreatePIOrder,
            params: data,
            file: this.form.file.file,
            configCode: "file_PI"
          });
          this.$message.success(this.$t("Creating a large file event requires waiting for the upload to complete before creating the event"));
          this.CloseDrawer(true);
        } else {
          this.buttonLoading = true;
          createCreatePIOrder(data).then((response) => {
            this.$message.success(this.$t('home.Createdsuccessfully'));
            this.CloseDrawer(true);
            this.$emit('afterCreateOrder', {});
            this.$myEvent.emit("onPurchaseList", false);
          }).finally(() => (this.buttonLoading = false));
        }
      });
    },
    updateOrder() {
      this.$refs.createForm.validate((valid) => {
        if (!valid) return;
        this.buttonLoading = true;
        let ciObj = this.form.ci ? {
          ci: this.form.ci
        } : {}
        updateBusinessOrder({
          ...ciObj,
          orderId: this.form.orderId,
          pi: this.form.pi,
          currency: this.form.currency,
          amount: this.form.amount,
          importCountry: this.form.importCountry
        }).then((data) => {
          if(data) {
            this.$message.success(this.$t('order.Update succeeded'));
            this.$emit('afterCreateOrder', {});
            this.CloseDrawer(true);
          }
        }).finally(() => (this.buttonLoading = false));
      });
    },
    CloseDrawer(val) {
      this.isOrderImport = false;
      this.addShipmentShow = false;
      if(val) {
        this.form = {
          Ppi: '',
          ci: '',
          importCountry: '',
          exportCountry: '',
          amount: undefined,
          currency: '',
          customerId: '',
          file: {
            file: null,
            fileId: ""
          },
          groupUsers: [],
          transaction_terms: "",
          forwarderCompanys: "",
          orderId: '',
        }
        this.forforwarderUserList = [];
      }
      this.$refs.createForm.clearValidate();
    },
  },
};
</script>
<style lang="less" scoped>
.selectUserBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  .avatarBox {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    background: #E0E4EA;
    .el-image {
      width: 16px;
      height: 16px;
    }
  }
  .groupName {
    margin-left: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #122545;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .edit {
    color: #076F49;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
  }
}
.purchaseBox {
  width: 100%;
  height: 60px;
  border-radius: 4px;
  background: #F7F9FC;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  .left {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #122545;
    font-weight: 600;
    gap: 9px;
    .iconBox {
      width: 29px;
      height: 29px;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 13px;
        color: #076F49;
      }
    }
  }
  .seePurchase {
    font-size: 12px;
    font-weight: 600;
    color: #076F49;
  }
}
</style>
