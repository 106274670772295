<template>
  <el-dialog
    :visible.sync="createOrderGroupShow"
    width="668px"
    :before-close="handleClose"
    :customerCompanyId="customerCompanyId"
    append-to-body
    @open="open()"
    v-loading="createGroupLoading"
    :title="$t('Files.Select people')"
    v-move-outside
  >
    <div class="dialog_body">
      <div class="groupSelect" v-if="!isGroup">
        <div class="left">
          <div class="title">{{ $tc('home.OrderRelatedPersonnel', $isOrderTitle()) }}</div>
          {{ $tc('order.members', (groupDetail && groupDetail.groupCompanies.length) || 0) }} 
        </div>
        <div class="right">
          <el-select 
            v-model="groupId" 
            :placeholder="$t('home.Select group')" 
            clearable
            style="width: 164px;"
            @change="handleGroupSelect">
            <template #prefix>
              <div class="avatarBox" v-if="groupDetail">
                <template v-for="row in groupDetail.groupCompanies">
                  <el-image
                    v-for="i in row.groupUsers"
                    :key="i.openId"
                    :src="i.avatarFileUrl"
                    fit="cover">
                  </el-image>
                </template>
              </div>
            </template>
            <el-option 
              v-for="(item, index) in groupOptions" 
              :key="index" 
              :value="item.groupId"
              :label="item.groupName">
              <div class="groupBox">
                <div class="avatarBox">
                  <template v-for="row in item.groupCompanies">
                    <el-image 
                      v-for="i in row.groupUsers" 
                      :key="i.openId" 
                      :src="i.avatarFileUrl"
                      fit="cover">
                    </el-image>
                  </template>
                </div>
                <div class="groupName">
                  <div class="name">{{ item.groupName }}</div>
                </div>
              </div>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="selectUserBox">
        <div class="selectTable">
          <div class="company" v-if="companyDetail">
            <div class="left">
              <img :src="companyDetail.companyLogoUrl" />
              <div class="content">
                <span>{{ companyDetail.aliasName }}</span> | {{ $t('order.Member') }}
              </div>
            </div>
            <el-checkbox v-model="companyAllSelect" />
          </div>
          <el-input 
            v-model="companySearch" 
            size="small"
            :placeholder="$t('Files.Search')"
            suffix-icon="el-icon-search"
          ></el-input>
          <div class="subject_table" v-if="companyId">
            <div class="item" v-for="(item, index) in filterOptions_company" :key="index">
              <member
                :info="item"
                :size="48"
                :placement="'left'"
              />
              <div class="content">
                <div class="nameBox">
                  <span class="name">{{ item.nickName }}</span>
                  <span class="manageTag" v-if="!item.type">{{ $t('memberList.manage') }}</span>
                </div>
                <div class="duties">
                  {{ item.position }}
                </div>
              </div>
              <el-checkbox v-model="item.check" :disabled="businessUserId == item.openId || item.type == 0"></el-checkbox>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="selectTable">
          <div class="company" v-if="customerDetail">
            <div class="left">
              <img :src="customerDetail.companyLogoUrl" />
              <div class="content">
                <span>{{ customerDetail.aliasName }}</span> | {{ $t('home.customerTitle') }}
              </div>
            </div>
            <el-checkbox v-model="customerAllSelect" />
          </div>
          <el-input 
            v-model="customerSearch" 
            size="small"
            :placeholder="$t('Files.Search')"
            suffix-icon="el-icon-search"
          ></el-input>
          <div class="subject_table" v-if="companyId">
            <div class="item" v-for="(item, index) in filterOptions_customer" :key="index">
              <member
                :info="item"
                :size="48"
                :placement="'left'"
              />
              <div class="content">
                <div class="nameBox">
                  <span class="name">{{ item.nickName }}</span>
                  <span class="manageTag" v-if="!item.type">{{ $t('memberList.manage') }}</span>
                </div>
                <div class="duties">
                  {{ item.position }}
                </div>
              </div>
              <el-checkbox v-model="item.check" :disabled="item.type == 0"></el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button 
        class="cancel_button" 
        size="small" 
        @click="handleClose">
        {{ $t('home.Cancel') }}
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="addGroup()"
        v-if="isGroup"
        >{{ $t('home.Confirm') }}</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="groupConfirm()"
        v-if="!isGroup"
        >{{ $t("home.People")+$t('home.Confirm') }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { companyMember, createGroup, updateGroup } from "../../api/common";
import { getGounpCompanyList } from '@/api/customer';
export default {
  name: "createOrderGroup",
  data() {
    return {
      groupId: "",
      groupOptions: [],
      groupDetail: null,
      form: {
        company: "",
        companyId: "",
        showList: [],
      },
      companySearch: "",
      customerSearch: "",
      options_company: [],
      options_customer: [],
      customerDetail: null,
      companyDetail: null,
      multipleSelection: [],
      multipleSelection_customer: [],
      loadingOptionsCompany: false,
      loadingOptionsCustomer: false,
      groupReferenceLoading: false,
      createGroupLoading: false,
      customerAllSelect: false,
      companyAllSelect: false
    };
  },
  props: [
    "createOrderGroupShow",
    "customerCompanyId",
    "groupUserList",
    "type",
    "groupCurrentId",
    "groupName",
    "isGroup",
    "purchase"
  ],
  computed: {
    ...mapGetters(["timezone", "country", "companyId", "businessUserId"]),
    filterOptions_company() {
      return this.options_company.filter(item => {
        return item.nickName.toLowerCase().indexOf(this.companySearch.toLowerCase()) !== -1
      })
    },
    filterOptions_customer() {
      return this.options_customer.filter(item => {
        return item.nickName.toLowerCase().indexOf(this.customerSearch.toLowerCase()) !== -1
      })
    }
  },
  watch: {
    companyAllSelect(val) {
      this.options_company.forEach(item => {
        if(item.type != 0 || (this.businessUserId != item.openId)) {
          item.check = val;
        }
      })
    },
    customerAllSelect(val) {
      this.options_customer.forEach(item => {
        if(item.type != 0) {
          item.check = val;
        }
      })
    },
    options_company: {
      handler(val) {
        let isCheck = val.map(item => item.check);
        this.companyAllSelect = !isCheck.includes(false);
      },
      deep: true
    },
    options_customer: {
      handler(val) {
        let isCheck = val.map(item => item.check);
        this.customerAllSelect = !isCheck.includes(false);
      },
      deep: true
    }
  },
  methods: {
    //由于mounted不执行，所以用el-dialog回调函数
    open() {
      if (this.customerCompanyId) {
        this.loadingOptionsCompany = true;
        this.loadingOptionsCustomer = true;
        companyMember(this.customerCompanyId.split("|")[1]).then((data) => {
          this.groupOptions = data;
          if(this.groupId) {
            this.handleGroupSelect(this.groupId);
            return;
          }
        })
        this.getList();
      } else {
        this.options_customer = [];
        this.options = [];
      }
    },
    async getList() {
      let propsList = this.groupUserList.map(item => item.openId);
      getGounpCompanyList(this.customerCompanyId.split("|")[0]).then((data) => {
        if(data[this.customerCompanyId.split("|")[1]]) {
          this.options_customer = data[this.customerCompanyId.split("|")[1]].memberList.map(item => ({
            ...item,
            check: propsList.includes(item.openId) || item.type == 0
          }));
          if(this.purchase) {
            this.options_customer.forEach(item => {
              if(item.openId == this.purchase.buyerUserId) {
                item.check = true;
              }
            })
          }
          this.customerDetail = data[this.customerCompanyId.split("|")[1]];
        }
        if(data[this.companyId]) {
          this.options_company = data[this.companyId].memberList.map(item => ({
            ...item,
            check: propsList.includes(item.openId) || item.type == 0
          }));
          this.options_company = this.options_company.map(item => ({
            ...item,
            check: item.openId == this.businessUserId ? true : item.check
          }));
          if(this.purchase) {
            this.options_company.forEach(item => {
              if(item.openId == this.purchase.sellerUserId) {
                item.check = true;
              }
            })
          }
          this.companyDetail = data[this.companyId];
        }
      });
    },
    setListData(data) {
      let company = data[0];
      let customer = data[1];
      let arr = [...company.groupUsers, ...customer.groupUsers];
      let propsList = arr.map(item => item.openId);
      this.options_company = this.options_company.map(item => ({
        ...item,
        check: propsList.includes(item.openId)
      }));
      this.options_customer = this.options_customer.map(item => ({
        ...item,
        check: propsList.includes(item.openId)
      }));
    },
    handleGroupSelect(val) {
      this.groupDetail = this.groupOptions.find(item => item.groupId === val);
      if(!this.groupDetail) {
        this.getList();
        return;
      }
      this.setListData(this.groupDetail.groupCompanies, true);
    },
    handleClose() {
      this.$emit("handleClose");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange_customer(val) {
      this.multipleSelection_customer = val;
    },
    groupConfirm() {
      let arr1 = this.options_company.filter(item => item.check);
      let arr2 = this.options_customer.filter(item => item.check);
      let arr = arr1.concat(arr2);
      //向父组件传回去userId列表
      this.$emit("update:groupUserList", arr);
      this.$emit("handleClose");
    },
    addGroup() {
      this.$prompt(this.$t('home.Please enter a group name'), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.groupName,
        inputValidator: (value) => {
          if(!value) {
            return "群组名称不能为空"
          }
        },
        inputErrorMessage: this.$t('home.Please enter a group name')
      }).then(({ value }) => {
        this.createGroupLoading = true;
        let arr1 = this.options_company.filter(item => item.check);
        let arr2 = this.options_customer.filter(item => item.check);
        let arr = arr1.concat(arr2);
        const body = {
          groupName: value,
          groupUsers: arr.map(item => item.openId).join(","),
          customerId: this.customerDetail.companyId,
          companyId: this.companyDetail.companyId
        };
        if(this.type === 'add') {
          createGroup(body).then(() => {
            this.$message({
              message: this.$t("home.createSuccess"),
              type: 'success',
              duration: 1000,
            });
            this.handleClose();
          }).finally(() => {
            this.createGroupLoading = false;
          })
          return;
        }
        updateGroup({
          groupName: value,
          groupUsers: arr.map(item => item.openId).join(","),
          groupId: this.groupCurrentId,
        }).then(() => {
          this.$message({
            message: this.$t("home.editSuccess"),
            type: 'success',
            duration: 1000,
          });
          this.handleClose();
        }).finally(() => {
          this.createGroupLoading = false;
        })
      }).catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
  padding: 5px 20px 20px;
  border-top: none;

  /deep/.el-dialog__header {
    padding: 0 !important;
  }
}

.dialog_body {
  position: relative;
  .groupSelect {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
    .avatarBox {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      background: #E0E4EA;
      .el-image {
        width: 16px;
        height: 16px;
      }
    }
    .left {
      font-size: 12px;
      line-height: 17px;
      color: #757D8A;
      .title {
        font-size: 14px;
        line-height: 20px;
        color: #122545;
        font-weight: 500;
        margin-bottom: 4px;
      }
    }
    /deep/ .el-select {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      vertical-align: top;
      .el-input {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        input {
          // width: 120px;
          padding: 0 32px 0 8px;
          border: none !important;
          color: #122545;
          font-size: 14px;
        }
        .el-input__prefix {
          display: flex;
          align-items: center;
          position: relative;
          left: 0;
          margin-left: 10px;
        }
        .el-input__suffix {

        }
      }
      .el-select-dropdown {
        .el-scrollbar {
          .el-select-dropdown__wrap {
            .el-select-dropdown__list {
              li {
                height: 45px;
                line-height: 45px;
                display: flex;
                align-items: center;
                padding: 0 10px;
              }
            }
          }
        }
      }
    }
    .groupBox {
      display: flex;
      align-items: center;
      margin: 5px 0;
      .groupName {
        margin-left: 8px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #122545;
        flex: 1;
        max-width: 116px;
        overflow: hidden;
        .name {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .selectUserBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .selectTable {
      flex: 1;
      .company {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 9px;
        padding-right: 20px;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 28px;
            height: 21px;
            border-radius: 4px;
            object-fit: cover;
            margin-right: 6px;
          }
          .content {
            font-size: 12px;
            line-height: 17px;
            color: #122545;
            span {
              font-size: 16px;
              line-height: 28px;
              font-weight: 600;
            }
          }
        }
      }
    }
    .line {
      width: 1px;
      height: 350px;
      margin: 0 25px;
      background: #E0E4EA;
    }
  }
  .subject_table {
    margin-top: 10px;
    height: 280px;
    overflow: overlay;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      cursor: pointer;
      &:hover {
        background: #F7F9FC;
      }
      .content {
        margin-left: 10px;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .nameBox {
          font-size: 14px;
          line-height: 20px;
          color: #122545;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          .name {
            display: inline-block;
            max-width: 140px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .manageTag {
            font-size: 12px;
            line-height: 17px;
            padding: 1px 5px;
            background: #F7F9FC;
            color: #076F49;
            border-radius: 4px;
            margin-left: 6px;
          }
        }
        .duties {
          font-size: 12px;
          line-height: 17px;
          color: #757D8A;
          width: 140px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
